import React, { useState } from "react"
import { Link } from "gatsby"
import { useStore } from "../store"
import slugify from "slugify"
import Image from "gatsby-image"
import useBooks from "../hooks/useBooks"

const AllBooks = ({ initial }) => {
  const books = useBooks()

  const { searchName, sortDesc, tag } = useStore(state => state)

  const renderBooks = books => {
    const _books = books
      .sort((a, b) => a.uid - b.uid)
      .reverse()
      .filter(book =>
        searchName.length > 0
          ? book.title.toLowerCase().includes(searchName.toLowerCase()) ||
            book.autors[0].toLowerCase().includes(searchName.toLowerCase()) ||
            book.colleccio.toLowerCase().includes(searchName.toLowerCase()) ||
            book.tags
              .map(e => e.toLowerCase())
              .includes(searchName.toLowerCase())
          : book
      )

    return {
      leng: _books.length,
      result: _books.map(book => {
        return (
          <>
            <div class="flex mb-2 border-myblack border-b overflow-hidden">
              <div class="w-auto h-auto">
                <Link
                  to={`/cataleg/${slugify(book.title, { lower: true })}`}
                  className="cursor-pointer"
                >
                  <Image
                    fluid={book.imatge.small.fluid}
                    className="h-16 w-12"
                  />
                </Link>
              </div>
              <div class="w-full  h-auto">
                <p className="ml-4">
                  <Link
                    to={`/cataleg/${slugify(book.title, { lower: true })}`}
                    className="cursor-pointer"
                  >
                    <span className="text-md hover:underline  font-title italic font-bold truncate overflow-hidden">
                      {book.title}
                    </span>
                  </Link>
                </p>

                <p className="ml-4 -my-2">
                  <span className="text-md font-title">{book.autors[0]}</span>
                </p>

                <p className="ml-4 mt-2">
                  <span className="text-md font-title text-malesherbes">
                    {book.preu.toFixed(2)} €
                  </span>
                </p>
                <p className="ml-4 mt-1 text-xs truncate">
                  Col·lecció {book.colleccio}{" "}
                  {book.num === 0 ? `` : `${book.num}`}{" "}
                  <span className="italic">{book?.tags?.join(", ")}</span>
                </p>
              </div>
            </div>
          </>
        )
      }),
    }
  }

  return <>{renderBooks(books).result}</>
}

export default AllBooks
